import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import ServiciosCloud from "../../components/services/servicios_cloud"

const Optimizacion = () => {
  const data = useStaticQuery(graphql`
    query Images_optimizacion {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Servicios de Optimización de Costes de la Nube | Qualoom</title>
        <meta name="title" content="Servicios de Optimización de Costes de la Nube | Qualoom"/>
        <meta name="description" content="Soluciones de Optimizacion de Costes en la Nube para empresas en los Procesos informáticos, que se traduce en una Reducción de los Costes. ¡Consúltanos!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/optimizacion-costes/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/optimizacion-costes/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/optimizacion-costes/"/>
        <meta property="og:title" content="Servicios de Optimización de Costes de la Nube | Qualoom"/>
        <meta property="og:description" content="Soluciones de Optimizacion de Costes en la Nube para empresas en los Procesos informáticos, que se traduce en una Reducción de los Costes. ¡Consúltanos!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/optimizacion-costes/"/>
        <meta property="twitter:title" content="Servicios de Optimización de Costes de la Nube | Qualoom"/>
        <meta property="twitter:description" content="Soluciones de Optimizacion de Costes en la Nube para empresas en los Procesos informáticos, que se traduce en una Reducción de los Costes. ¡Consúltanos!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/optimizacion-costes/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Optimización de Costes", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/optimizacion-costes/", "name": "Optimización de Costes"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Optimización de Costes</h1>
              <p>Se trata de una de las cuestiones más planteadas por los responsables de sistemas de información en todas las organizaciones. ¿El coste mensual y anual de los sistemas de información se encuentra correctamente justificado y alineado con los beneficios de su uso? ¿Están los sistemas lo correctamente optimizados que puede estar?</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es y para qué sirve la optimización de costes?</h2><hr></hr>
          <p>La optimización de costes no es una simple reducción de los gastos, es más apropiado relacionarlo con una <b>gestión de los costes de la empresa con el objetivo de aumentar su rentabilidad.</b></p>
          <p>Se deben analizar todas las actividades para identificar aquellas que no aportan un valor a la empresa y aquellas que sí lo aportan para poder optimizar su rendimiento y mejorar sus resultados. De esta forma, <b>la optimización de costes ayudará a gestionar los mismos e invertirlos en aquellas actividades que mejorarán aspectos de tu negocio</b>.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Soluciones de optimización de costes en la nube</h2><hr></hr>
          <p>En ecosistemas Cloud, la principal ventaja en este apartado es la optimización de costes, es decir, si se necesita más potencia, siempre podremos escalar los servicios y si se necesita menos, podremos reducir el número de servicios o su tipología. </p>
          <p>Todas estas tareas de optimización <b>son el resultado de analizar los indicadores de los sistemas en cuanto a rendimiento, capacidad y uso</b>, de forma que podamos tomar decisiones manuales o incluso implementar acciones automatizadas con base en eventos y ajustar los servicios de manera autónoma.</p><br/>
          <h3>Ahorro de costes Cloud</h3>
          <p>En este sentido <b>Amazon Web Services, Azure y Google Cloud Platform ofrecen un completo set de herramientas</b> como por ejemplo Cost Explorer de AWS que permite analizar pormenorizadamente los detalles de costes por cada uno de los servicios de nuestros sistemas, lo que permitirá tomar decisiones que se verán reflejadas en la factura mensual.</p>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué soluciones ofrece Qualoom?</h2><hr></hr>
          <ServiciosCloud></ServiciosCloud>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Optimizacion